import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useCustomer } from 'hooks';
import { transformICompensationRequestListItemToTableData } from './helpers';
import { COMPENSATION_REQUEST_API } from 'configs/api/compensationRequest';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { ICompensationRequestListItem } from 'types/api';
import { ICompensationRequestTabTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import TableWrapper from 'components/Table';
import { COMPENSATION_TAB_TABLE_COLS_CONFIG } from './config';
import { ColumnsType } from 'antd/es/table';
import { EyeOutlined } from '@ant-design/icons';

const CompensationsTab: FC = () => {
	const { customerId } = useCustomer();
	const { t: tCompensations } = useTranslation('compensation-requests');

	// ! memos
	const requestConfig = useMemo(() => COMPENSATION_REQUEST_API.list(customerId), [customerId]);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! config
	const tableColumns = useMemo<ColumnsType<ICompensationRequestTabTableData>>(
		() => [
			...COMPENSATION_TAB_TABLE_COLS_CONFIG(countryIsoTwoCode),
			{
				key: 'actions',
				title: tCompensations('shared.entity.actions'),
				width: getActionButtonsColumnWidth(1),
				fixed: 'right',
				render: (_, record) => {
					const actions: IActionMenuItem[] = [
						{
							type: 'link',
							title: tCompensations('action_buttons.view_details'),
							icon: <EyeOutlined />,
							navigateTo: generatePath(ABSOLUTE_ROUTES.COMPENSATION_REQUEST_DETAILS, {
								countryIsoTwoCode,
								compensationRequestId: record.id,
							}),
						},
					];
					return <ActionsMenu actions={actions} />;
				},
			},
		],
		[countryIsoTwoCode, tCompensations]
	);

	// ! render
	return (
		<TableWrapper<ICompensationRequestTabTableData, ICompensationRequestListItem>
			disableSearch
			columns={tableColumns}
			requestConfig={requestConfig}
			transformDataToTableData={transformICompensationRequestListItemToTableData}
		/>
	);
};

export default CompensationsTab;
