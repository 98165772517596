import { ReactNode } from 'react';
import {
	EOrderBookingTypes,
	EOrderDeliveryStatus,
	EOrderDisputedStatus,
	EOrderPaymentMethods,
	EOrderPaymentStatus,
	EOrderStatus,
	EOrderType,
	IDispatcherOrderBranch,
	IDispatcherOrderDriver,
	IOrderActions,
	IOrderIssue,
	IOrderMetrics,
} from 'types/api';
import { EAssignmentMode, EDeliveryMode } from 'types/common';
import { IDispatcherOrderTimelineStep } from './OrdersCards/DispatcherOrderTimeline';

// ! types & enums

export declare type TDispatcherTabs = keyof typeof EDispatcherTab;

export enum EDispatcherTab {
	AWAITING_PAYMENT = 'AWAITING_PAYMENT',
	SCHEDULED = 'SCHEDULED',
	NEW = 'NEW',
	PREPARING = 'PREPARING',
	READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
	DISPATCHED = 'DISPATCHED',
	DISPUTED = 'DISPUTED',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
	FAILED = 'FAILED',
	INTERVENTION = 'INTERVENTION',
}

export enum EDispatcherOrderCardActionsBtnKeys {
	CAN_TRANSITION_TO_NEW = 'can_transition_to_new',
	CAN_PREPARE = 'can_prepare',
	CAN_MARK_AS_READY = 'can_mark_as_ready',
	CAN_ASSIGN_DRIVER = 'can_assign_driver',
	CAN_UNASSIGN_DRIVER = 'can_unassign_driver',
	CAN_COMPLETE = 'can_complete',
	CAN_CANCEL = 'can_cancel',
	CAN_REJECT = 'can_reject',
	CAN_CLEAR_DISPUTE = 'can_clear_dispute',
}

// ! interfaces
export interface IDispatcherTabConfig {
	key: EDispatcherTab;
	labelKey: string;
	icon?: ReactNode;
	filter: Partial<IBranchDispatcherOrderListFilterRequest>;
	extractMetricFn: (metrics: IOrderMetrics) => number | undefined;
}

export interface IOrderCardData {
	branch: IDispatcherOrderBranch;
	status: EOrderStatus;
	status_description: string;
	order_type: EOrderType;
	order_id: number;
	order_code: string;
	order_placed_at: string;
	order_new_at: string;
	order_min_customer_delivery_eta_at: string;
	order_max_customer_delivery_eta_at: string;
	payment_type: EOrderPaymentMethods;
	payment_type_icon: ReactNode;
	payment_status: EOrderPaymentStatus;
	total_cost: string;
	booking_type: EOrderBookingTypes;
	pickup_time?: string;
	pickup_address: string;
	pickup_address_latlng: {
		lat: string;
		lng: string;
	};
	delivery_status: EOrderDeliveryStatus;
	delivery_address: string;
	delivery_address_latlng: {
		lat: string;
		lng: string;
	};
	dispute_status: EOrderDisputedStatus;
	dispute_status_description: string;
	driver_id?: number;
	driver?: IDispatcherOrderDriver;
	timeline: IDispatcherOrderTimelineStep[];
	actions: IOrderActions;
	assignment_mode: EAssignmentMode;
	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;
	issue: IOrderIssue;
}

interface IOrderingReq {
	sort_by?: string;
	order_by?: string;
}
interface IPaginationReq {
	page?: string;
	per_page?: string;
}
export interface IBranchDispatcherOrderListFilterRequest extends IPaginationReq, IOrderingReq {
	start_date?: string;
	end_date?: string;
	status?: EOrderStatus | EOrderStatus[];
	delivery_status?: EOrderDeliveryStatus | EOrderDeliveryStatus[];
	dispute_status?: EOrderDisputedStatus | EOrderDisputedStatus[];
	booking_type?: EOrderBookingTypes;
}
