import { Nullable } from 'types/common';
import { IBinCardSegmentListItemResponse } from './bin-card-segments';
import dayjs, { Dayjs } from 'dayjs';

export enum EPromotionType {
	CODE = 'code',
	RECOMMENDATION = 'recommendation',
	CAMPAIGN = 'campaign',
}

export enum EPromotionSource {
	PLATFORM = 'platform',
	VENDOR = 'vendor',
}

export enum EPromotionUsageType {
	MANUAL = 'manual',
	AUTOMATIC = 'automatic',
}

export enum EPromotionDiscountType {
	PERCENTAGE = 'percentage',
	AMOUNT = 'amount',
	FREE_DELIVERY = 'free_delivery',
	CASHBACK_PERCENTAGE = 'cashback_percentage',
	CASHBACK_AMOUNT = 'cashback_amount',
}

export enum EPromotionVisibility {
	PRIVATE = 'private',
	PUBLIC = 'public',
}

export enum EPromotionTarget {
	DEFAULT = 'default',
	RETENTION = 'retention',
	ACQUISITION = 'acquisition',
}

export enum EPromotionSegment {
	SPECIFIC_CUSTOMER = 'specific_customer',
	SPECIFIC_VENDOR_AND_BRANCH = 'specific_vendor_and_branch',
}

export enum EPromotionCampaignCustomerSegment {
	PURCHASED_FROM_SPECIFIC_CUSTOMER = 'purchased_from_specific_customer',
	PURCHASED_FROM_SPECIFIC_VENDOR = 'purchased_from_specific_vendor',
	PURCHASED_FROM_MULTIPLE_VENDORS = 'purchased_from_multiple_vendors',
}

export enum EPromotionCampaignCustomerSegmentFields {
	SEGMENT = 'segment',
	SEGMENT_FROM_DATE = 'segment_from_date',
	SEGMENT_TO_DATE = 'segment_to_date',
	NUMBER_OF_ORDERS = 'number_of_orders',
	VENDOR_ID = 'vendor_id',
	STORE_ID = 'store_id',
	CUSTOMER_ID = 'customer_id',
}

export enum EPromotionStatus {
	PAUSED = 'paused',
	PENDING = 'pending',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
}

export enum EPromotionRecommendationStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	EXPIRED = 'expired',
	USED = 'used',
}

export enum EPlatformType {
	MOBILE = 'mobile',
	WEB = 'web',
	ALL = 'all',
}

export interface EPromotionActions {
	can_edit: boolean;
	can_launch: boolean;
	can_pause: boolean;
	can_reactivate: boolean;
	can_resume: boolean;
	can_revoke: boolean;
	can_stop: boolean;
	can_update_end_date: boolean;
	can_show_associated_vendors: boolean;
}

// FE TYPES

export enum ECampaignFormFields {
	ID = 'id',
	TYPE = 'type',
	TARGET = 'target',
	USAGE_TYPE = 'usage_type',
	FREE_DELIVERY = 'free_delivery',
	DISCOUNT_AMOUNT = 'discount_amount',
	INCENTIVE_CURRENCY = 'incentive_currency',

	TITLE = 'title',
	TITLE_AR = 'title_ar',
	SHORT_TITLE = 'short_title',
	SHORT_TITLE_AR = 'short_title_ar',
	DESCRIPTION = 'description',
	DESCRIPTION_AR = 'description_ar',
	CODE = 'code',

	TIME_RANGE = 'time_range',
	START_DATE = 'start_date',
	END_DATE = 'end_date',
	SUGGESTION_EXPIRY_DATE = 'suggestion_expiry_date',

	RECOMMENDER = 'recommender',
	CREATED_BY = 'created_by',
	CREATED_AT = 'created_at',

	STATUS = 'status',
	RECOMMENDATION_STATUS = 'recommendation_status',
	SOURCE = 'source',
	DISCOUNT_TYPE = 'discount_type',
	VISIBILITY = 'visibility',
	AMOUNT = 'discount_amount',
	MINIMUM_ORDER_AMOUNT = 'min_order_amount',
	MAXIMUM_DISCOUNT_AMOUNT = 'max_discount_amount',
	GLOBAL_USAGE_LIMIT = 'global_usage_limit',
	GLOBAL_USAGE_COUNT = 'global_usage_count',
	USER_USAGE_LIMIT = 'user_usage_limit',

	CA_BUSINESS_MODEL_ONLY = 'ca_business_model_only',
	CARI_UNLIMITED_ONLY = 'cari_unlimited_only',
	FIRST_ORDER_IN_VENDOR_ONLY = 'first_order_in_vendor_only',
	SECOND_ORDER_ONWARDS_IN_VENDOR_ONLY = 'second_order_onwards_in_vendor_only',
	SPECIFIC_NTH_ORDER_IN_VENDOR = 'specific_nth_order_in_vendor',
	IS_ACCUMULATIVE = 'is_accumulative',
	PLATFORM_TYPE = 'platform_type',

	LAUNCH_ON_CREATE = 'launch_on_create',
	EXTERNAL_ID = 'external_id',

	// SEGMENTS
	CUSTOMER_SEGMENT = 'customer_segments',

	VENDOR_NAME = 'vendor_name',
	CUSTOMER_ID = 'customer_id',

	MULTI_VENDOR = 'vendor_list',
	MULTI_VENDOR_VENDOR_ID = 'id',
	MULTI_VENDOR_BRANCH_LIST = 'store_list',

	BIN_SEGMENT = 'bin_segment_list',

	OFFER_LABELS = 'offer_label_ids',
}

export interface ICampaignVendorFormField {
	[ECampaignFormFields.MULTI_VENDOR_VENDOR_ID]: number;
	[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]: number[];
}

interface ICampaignSegments {
	[ECampaignFormFields.CUSTOMER_SEGMENT]: Partial<{
		[EPromotionCampaignCustomerSegment.PURCHASED_FROM_SPECIFIC_CUSTOMER]: Array<{
			[EPromotionCampaignCustomerSegmentFields.CUSTOMER_ID]: number;
		}>;
		[EPromotionCampaignCustomerSegment.PURCHASED_FROM_MULTIPLE_VENDORS]: boolean;
		[EPromotionCampaignCustomerSegment.PURCHASED_FROM_SPECIFIC_VENDOR]: boolean;
	}>;
}

export interface ICampaignForm extends ICampaignSegments {
	id?: string | number;

	[ECampaignFormFields.TYPE]: EPromotionType;
	[ECampaignFormFields.TARGET]: EPromotionTarget;

	[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType;

	// TO QUESTION:
	[ECampaignFormFields.FREE_DELIVERY]: boolean;

	[ECampaignFormFields.CODE]: Nullable<string>;

	[ECampaignFormFields.TITLE]: string;
	[ECampaignFormFields.TITLE_AR]: string;
	[ECampaignFormFields.DESCRIPTION]: string;
	[ECampaignFormFields.DESCRIPTION_AR]: string;
	[ECampaignFormFields.START_DATE]: Nullable<dayjs.Dayjs>;
	[ECampaignFormFields.END_DATE]: Nullable<dayjs.Dayjs>;
	[ECampaignFormFields.TIME_RANGE]?: number;
	[ECampaignFormFields.SUGGESTION_EXPIRY_DATE]?: dayjs.Dayjs;

	// calculation fields
	[ECampaignFormFields.STATUS]: EPromotionStatus;
	[ECampaignFormFields.RECOMMENDATION_STATUS]: EPromotionRecommendationStatus;

	[ECampaignFormFields.SOURCE]: EPromotionSource;
	[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType;
	[ECampaignFormFields.VISIBILITY]: EPromotionVisibility;
	[ECampaignFormFields.AMOUNT]: number;
	[ECampaignFormFields.MINIMUM_ORDER_AMOUNT]: number;
	[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT]: number;
	[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: number;
	[ECampaignFormFields.USER_USAGE_LIMIT]: number;

	[ECampaignFormFields.CA_BUSINESS_MODEL_ONLY]?: boolean;
	[ECampaignFormFields.CARI_UNLIMITED_ONLY]?: boolean;
	[ECampaignFormFields.FIRST_ORDER_IN_VENDOR_ONLY]?: boolean;
	[ECampaignFormFields.SECOND_ORDER_ONWARDS_IN_VENDOR_ONLY]?: boolean;
	[ECampaignFormFields.SPECIFIC_NTH_ORDER_IN_VENDOR]?: number;
	[ECampaignFormFields.IS_ACCUMULATIVE]?: boolean;
	[ECampaignFormFields.PLATFORM_TYPE]: EPlatformType;
	[ECampaignFormFields.BIN_SEGMENT]: number[];
	[ECampaignFormFields.OFFER_LABELS]: number[];

	[ECampaignFormFields.EXTERNAL_ID]: Nullable<string>;

	// optional fields
	[ECampaignFormFields.CUSTOMER_ID]?: number;

	[ECampaignFormFields.MULTI_VENDOR]: Array<ICampaignVendorFormField>;
}

interface IPromotionTargetEntity {
	name: string;
	id: number;
}

// BE => OperationsPromotionResponse

export interface IPromotionVendorDetails extends IPromotionTargetEntity {
	[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]?: Array<IPromotionTargetEntity>;
}

export interface IPromotionGenerateCodeResponse {
	[ECampaignFormFields.CODE]: string;
}
export interface IPromotionCreatePayloadTemp {
	[ECampaignFormFields.CODE]: Nullable<string>;
	[ECampaignFormFields.TARGET]: EPromotionTarget;
	[ECampaignFormFields.TYPE]: EPromotionType;

	[ECampaignFormFields.START_DATE]?: string;
	[ECampaignFormFields.END_DATE]?: string;
	[ECampaignFormFields.TIME_RANGE]?: number;
	[ECampaignFormFields.SUGGESTION_EXPIRY_DATE]?: string;

	[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType;
	[ECampaignFormFields.DISCOUNT_AMOUNT]: number;
	[ECampaignFormFields.TITLE]: string;
	[ECampaignFormFields.TITLE_AR]: string;
	[ECampaignFormFields.DESCRIPTION]: string;
	[ECampaignFormFields.DESCRIPTION_AR]: string;
	[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType;
	[ECampaignFormFields.EXTERNAL_ID]: Nullable<string>;

	min_order_amount: number;
	max_discount_amount: number;
	usage_limit_per_user: number;
	[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: number;
	[ECampaignFormFields.CA_BUSINESS_MODEL_ONLY]?: boolean;
	[ECampaignFormFields.CARI_UNLIMITED_ONLY]?: boolean;
	[ECampaignFormFields.FIRST_ORDER_IN_VENDOR_ONLY]?: boolean;
	[ECampaignFormFields.FREE_DELIVERY]: boolean;
	[ECampaignFormFields.SECOND_ORDER_ONWARDS_IN_VENDOR_ONLY]?: boolean;
	[ECampaignFormFields.SPECIFIC_NTH_ORDER_IN_VENDOR]?: number;
	[ECampaignFormFields.IS_ACCUMULATIVE]?: boolean;
	[ECampaignFormFields.PLATFORM_TYPE]: EPlatformType;
	[ECampaignFormFields.BIN_SEGMENT]: number[];

	[ECampaignFormFields.STATUS]: EPromotionStatus;
	[ECampaignFormFields.VISIBILITY]: EPromotionVisibility;

	[ECampaignFormFields.LAUNCH_ON_CREATE]: boolean;

	// segment
	segment?: EPromotionSegment;
	customer_id?: number;

	[ECampaignFormFields.MULTI_VENDOR]: Array<ICampaignVendorFormField>;
}

// * NEW TYPES
interface IPromotionListInfo {
	title: string;
	title_ar: string; // request to send
	status: EPromotionStatus;
	created_at: Dayjs;
}

interface IPromotionCreatedBy {
	id: number;
	name: string;
}

export interface IPromotionAuthors {
	created_by: IPromotionCreatedBy;
}

interface IPromotionConfigurations {
	code: string;
	type: EPromotionType;
	source: EPromotionSource;
	visibility: EPromotionVisibility;
	target: EPromotionTarget;
	recommendation_status: EPromotionRecommendationStatus;
	discount_type: EPromotionDiscountType;
	usage_type: EPromotionUsageType;
}

interface IPromotionDuration {
	suggestion_expiry_date: Dayjs;
	start_date: Nullable<Dayjs>;
	end_date: Nullable<Dayjs>;
	time_range: number;
}

export interface IVendorPromotion {
	id: number;
	name?: string;
	is_multiple_vendor: boolean;
	number_of_vendors: number;
}

export interface IPromotionUsage {
	global_usage_count: number;
	global_usage_limit: number;
	user_usage_limit: number;
}

export interface IPromotionOfferLabel {
	id: number;
	name: string;
	name_ar: string;
}

export interface IPromotionListResponse {
	id: number;
	info: IPromotionListInfo;
	authors: IPromotionAuthors;
	configurations: IPromotionConfigurations;
	duration: IPromotionDuration;
	actions: EPromotionActions;
	vendor?: IVendorPromotion;
	usage: IPromotionUsage;
	offer_labels: IPromotionOfferLabel[];
}

// ! DETAILS

interface IPromotionDetailsInfo {
	title: string;
	title_ar: string;
	description: string;
	description_ar: string;
	status: EPromotionStatus;
	created_at: Dayjs;
}

export interface IPromotionAffectedCustomer {
	id: number;
	name: string;
}

interface IPromotionDetailsConfigurations {
	type: EPromotionType;
	source: EPromotionSource;
	visibility: EPromotionVisibility;
	target: EPromotionTarget;
	recommendation_status: EPromotionRecommendationStatus;
	code: Nullable<string>;
	customer_segments?: Nullable<EPromotionCampaignCustomerSegment>;
	customer: Nullable<IPromotionAffectedCustomer>;
	platform_type: EPlatformType;

	// calculations
	usage_type: EPromotionUsageType;
	discount_type: EPromotionDiscountType;
	discount_amount: number;
	min_order_amount: number;
	max_discount_amount: number;

	// restrictions
	free_delivery: boolean;
	external_id: Nullable<string>;

	// platform specific restrictions
	ca_business_model_only?: boolean;
	cari_unlimited_only?: boolean;
	first_order_in_vendor_only?: boolean;
	second_order_onwards_in_vendor_only?: boolean;
	specific_order_number_in_vendor?: number;
	specific_nth_order_in_vendor?: number;
	is_accumulative: boolean;
}

export interface IPromotionDetailsBinSegments {
	id: IBinCardSegmentListItemResponse['id'];
	name: IBinCardSegmentListItemResponse['name'];
	name_ar: IBinCardSegmentListItemResponse['name_ar'];
}

export interface IPromotionDetailsResponse {
	id: number;
	actions: EPromotionActions;
	duration: IPromotionDuration;
	authors: IPromotionAuthors;
	info: IPromotionDetailsInfo;
	configurations: IPromotionDetailsConfigurations;
	usage: IPromotionUsage;
	vendor: Nullable<IVendorPromotion>;
	bin_segments: IPromotionDetailsBinSegments[];
	offer_labels: IPromotionOfferLabel[];
}

export interface IPromotionAffectedVendorsResponse {
	vendor_id: number;
	vendor_name: string;
	vendor_name_ar: string;
	store_id: number;
	store_name: string;
	store_name_ar: string;
}
